<template>
  <form @submit.prevent="send">
    <div class="mb-2">
      <input
        class="appearance-none block w-full bg-gray-100 md:text-sm text-black border-b-2 mb-1 p-2 leading-tight focus:outline-none focus:bg-white focus:border-primary"
        :class="errors.firstname ? 'border-secondary' : 'border-thirdry'"
        type="text"
        placeholder="Imię"
        v-model="form.firstname"
      />
    </div>
    <div class="mb-2">
      <input
        class="appearance-none block w-full bg-gray-100 md:text-sm text-black border-b-2 mb-1 p-2 leading-tight focus:outline-none focus:bg-white focus:border-primary"
        :class="errors.lastname ? 'border-secondary' : 'border-thirdry'"
        type="text"
        placeholder="Nazwisko"
        v-model="form.lastname"
      />
    </div>
    <div class="mb-2">
      <input
        class="appearance-none block w-full bg-gray-100 md:text-sm text-black border-b-2 mb-1 p-2 leading-tight focus:outline-none focus:bg-white focus:border-primary"
        :class="errors.email ? 'border-secondary' : 'border-thirdry'"
        type="email"
        placeholder="E-mail"
        v-model="form.email"
      />
    </div>
    <div class="mb-2">
      <input
        class="appearance-none block w-full bg-gray-100 md:text-sm text-black border-b-2 mb-1 p-2 leading-tight focus:outline-none focus:bg-white focus:border-primary"
        :class="errors.phone ? 'border-secondary' : 'border-thirdry'"
        type="text"
        placeholder="Telefon"
        v-model="form.phone"
      />
    </div>
    <div class="mb-2">
      <label class="w-full block text-justify" :class="{ 'text-secondary': errors.check }">
        <input
          class="mr-2 leading-tight form-checkbox"
          type="checkbox"
          v-model="form.check"
          true-value="Wyrażam zgodę"
          :false-value="null"
        />
        <span class="text-sm">
          Zapoznałam/zapoznałem się i akceptuję&nbsp;
          <router-link
            to="regulamin"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:text-black underline"
          >regulamin</router-link>.
        </span>
      </label>
    </div>
    <div class="mb-2">
      <label class="w-full block text-justify" :class="{ 'text-secondary': errors.rodo }">
        <input
          class="mr-2 leading-tight form-checkbox"
          type="checkbox"
          v-model="form.rodo"
          true-value="Wyrażam zgodę"
          :false-value="null"
        />
        <span class="text-sm">
          Wyrażam zgodę na
          <router-link
            to="rodo"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:text-black underline"
          >zbieranie i przetwarzanie danych osobowych</router-link>&nbsp;oraz akceptuję
          <router-link
            to="polityka-prywatnosci"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:text-black underline"
          >politykę prywatności</router-link>&nbsp;serwisu.
        </span>
      </label>
    </div>
    <transition
      :duration="{ enter: 600, leave: 300 }"
      name="fade"
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="bg-red-600 text-white text-center p-2 rounded-lg" v-if="error">
        <h4>Nie wszystkie dane zostały poprawnie wypełnione!</h4>
      </div>
    </transition>
    <transition
      :duration="{ enter: 600, leave: 300 }"
      name="fade"
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="bg-green-500 text-white text-center p-2 rounded-lg" v-if="success">
        <h4>Dziękujemy za wysłanie zgłoszenia!</h4>
      </div>
    </transition>
    <div class="flex justify-center my-2">
      <button
        type="submit"
        class="mx-auto pulse rounded-full w-24 h-24 uppercase text-gray-200 bg-secondary font-sans text-sm tracking-wider leading-none"
      >
        Wysyłam
        <br />i
        <br />umawiam się
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      form: {},
      errors: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        check: null,
        rodo: null
      },
      error: false,
      success: false
    };
  },
  methods: {
    send() {
      axios
        .post("/api/send/appoint", this.form)
        .then(() => {
          this.success = true;
          this.form = {};
          this.error = false;
          this.errors = {
            firstname: null,
            lastname: null,
            email: null,
            phone: null,
            check: null,
            rodo: null
          };
          setTimeout(() => {
            this.success = false;
          }, 5000);
        })
        .catch(error => {
          this.error = true;
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>
