<template>
  <div div id="index" ref="index">
    <Index />
    <Copy />
  </div>
</template>

<script>
import Index from "@/components/Individual/Index";
import Copy from "@/components/Individual/Copy";

export default {
  name: "Individual",
  components: {
    Index,
    Copy
  }
};
</script>
