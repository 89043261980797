<template>
  <section class="w-full bg-thirdry text-gray-100 flex justify-center items-center min-h-screen">
    <div class="container px-5 py-10">
      <h2 class="lg:text-4xl text-2xl font-bold py-2 text-center">Indywidualne odkrycie</h2>
      <div class="flex md:flex-col">
        <div class="text-center lg:text-xl text-sm flex-1 px-5 md:pb-5" v-html="text"></div>
        <div class="flex-1 px-5">
          <h4
            class="text-center lg:text-2xl text-xl font-bold py-2"
          >Umów się na niezobowiązującą rozmowę.</h4>
          <Form />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Form from "@/components/Individual/Form";

export default {
  name: "Index",
  data() {
    return {
      text: ""
    };
  },
  components: {
    Form
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await axios.get("/api/app/7").then(response => {
        this.text = response.data.text;
      });
    }
  }
};
</script>
